<template>
  <div
    class="external-layout h-screen"
    :class="themeClassName"
    :style="`background-image:url('${backgroundImage}')`"
  >
    <div class="overlay"></div>
    <div class="box p-10 bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-xl max-h-screen overflow-y-auto">
      <div class="mb-10">
        <img
          class="external-logo"
          :src="logoUrl"
          alt="Logo"
        />
        <div class="mt-1 text-xs leading-none text-gray-700">APP: {{ currentPackageVersion }} | {{ info }}</div>
      </div>
      <div
        v-if="!status && !loading"
        class="grid"
      >
        <p class="mx-auto text-2xl">Tjenesten er for øyeblikket utilgjengelig.</p>
        <p>Besøk <a
            :href="websiteUrl"
            class="underline text-green-600"
          >våre nettsider</a> eller ta kontakt med din kontaktperson for mer
          informasjon.
        </p>
        <FontAwesomeIcon
          :icon="['far', 'construction']"
          class="text-5xl mx-auto mt-8"
        />
      </div>
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script>

import http from "@/http"
import { get } from "lodash"
import LocaleService from "@/services/LocaleService"
import TokenService from "@/services/TokenService"
import { getConfig, getTheme } from "@/config"

const config = getConfig();
const theme = getTheme()

export default {

  data() {
    return {
      status: null
    }
  },
  computed: {
    logoUrl() {
      return theme.logoBlack
    },
    backgroundImage() {
      return theme.backgroundImage
    },
    themeClassName() {
      return theme.themeClassName
    },
    currentPackageVersion() {
      return process.env.PACKAGE_VERSION
    },
    info() {
      return get(this.status, "info", "")
    },
    websiteUrl() {
      const end = config.hosts[0].split('.').pop()
      return `https://www.smartretur.${end === 'localhost' ? 'no' : end}`
    }
  },
  async created() {

    if (this.$route.query.auth) {
      TokenService.setToken(this.$route.query.auth)
    }

    this.$i18n.locale = LocaleService.getLocale()
    this.status = await http.get("Status")
  },
  mounted() {
    const locale = LocaleService.getLocale()
    document.documentElement.setAttribute('lang', locale.substr(0, 2));
  }

}
</script>


<style scoped>
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  z-index: 10;
}

@screen md {
  .box {
    left: 15%;
    right: auto;
    top: 50%;
    transform: translate(-15%, -50%);
  }
}
</style>
